<template>
  <div v-show="true" id="data-list-thumb-view" class="data-list-container">
    <div v-if="!(DataReady)">
      <div id="loadingRequestPending" class="vs-con-loading__container mt-4">
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
    <div v-if="DataReady">
      <vs-table
        ref="table"
        single
        v-model="selected"
        pagination
        :max-items="itemsPerPage"
        noDataText="🤦‍♂️📃No hay cotizaciones generadas..."
        search
        :data="ultimasCotizacione"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center margin-person flex-grow justify-between"
        >
          <div class="flex mb-10">
            <div class="w-1/2 bg-grid-color-secondary h-12 fecha1 p-2">
              <p>Fecha de inicio:</p>
              <datepicker
                :language="languages[language]"
                v-model="fromDate"
                placeholder="From Date"
              />
            </div>
            <div class="w-1/2 bg-grid-color h-12 fecha1 p-2">
              <p>Fecha de finalización:</p>

              <datepicker :language="languages[language]" v-model="toDate" placeholder="To Date" />
            </div>
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span
                class="mr-2"
                v-if="ultimasCotizacione && ultimasCotizacione.length > 0"
              >{{   itemsPerPage - (itemsPerPage - 1) }} - {{ ultimasCotizacione.length -   itemsPerPage > 0 ?   itemsPerPage : ultimasCotizacione.length }} of {{ queriedItems.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage=4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="barCode">ID / Estado</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="proyect_name">Proyecto</vs-th>
          <vs-th sort-key="number">Apto.</vs-th>
          <vs-th sort-key="bedrooms">Hab.</vs-th>
          <vs-th sort-key="bathrooms">Baños</vs-th>
          <vs-th sort-key="living_square_mts">m2</vs-th>
          <vs-th sort-key="quote_date_created">Fecha</vs-th>
          <vs-th sort-key="request">Descuento</vs-th>
          <vs-th>Acción</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="q" :key="indextr" v-for="(q, indextr) in data">
              <vs-td>
                <vs-chip :color="statusColor(q.quote_date_limit)">{{q.barCode}}</vs-chip>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">
                  <!-- {{q.client[0].email}} -->
                </p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{q.apartaments[0].proyect_name}}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{q.apartaments[0].number}}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{q.apartaments[0].bedrooms}}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{q.apartaments[0].bathrooms}}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{q.apartaments[0].living_square_mts}}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-small">{{fechaFormateada(q.quote_date_created)}}</p>
              </vs-td>
              <vs-td>
                <vs-chip :color="statusColorDiscount(q.request)">{{q.request}}</vs-chip>
              </vs-td>
              <vs-td>
                <vs-row>
                  <vx-tooltip color="primary" text="Visualizar" position="bottom">
                    <vs-button
                      @click="viewQuote(q._id)"
                      color="primary"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-eye"
                      class="p-1"
                      :to="{ name: 'Visualizador de Cotización'}"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip
                    v-if="q.request != 'Pendiente' && q.request != 'Aprobada' && q.request != 'Denegada'"
                    color="danger"
                    text="Solicitar descuento"
                    position="bottom"
                  >
                    <vs-button
                      @click="viewQuote(q._id), discountPopUp = true"
                      color="danger"
                      type="flat"
                      icon-pack="feather"
                      icon="icon-tag"
                      class="p-1"
                    ></vs-button>
                  </vx-tooltip>
                </vs-row>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import * as lang from "vuejs-datepicker/src/locale";
import Datepicker from "vuejs-datepicker";

export default {
  name: "ListadoProyectos",
  data() {
    return {
      DataReady: false,
      selected: [],
      getUser: [],
      itemsPerPage: 50,
      getData: false,
      levelSelected: "",
      isMounted: false,
      fromDate: null,
      toDate: null,
      language: "es",
      languages: lang,
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
    };
  },
  apollo: {
    getUser: {
      query: gql`
        query($id: ID!) {
          getUser(id: $id) {
            _id
            quotes {
              _id
              quote_date_limit
              quote_date_created
              barCode
              request
              client {
                _id
                first_name
                last_name
                email
              }
              apartaments {
                number
                proyect_id
                proyect_name
                bedrooms
                bathrooms
                living_square_mts
              }
              parkings {
                number
              }
              warehouses {
                number
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: localStorage.user_id,
        };
      },
      pollInterval: 700,
    },
  },
  methods: {

    openLoading() {
      this.$vs.loading({
        container: "#loadingRequestPending",
        scale: 1,
        type: "sound",
        background: "transparent",
        color: "danger",
      });
    },
    fechaFormateada(fecha) {
      let nuevaFecha = new Date(fecha);
      let dd = nuevaFecha.getDate();
      let mm = nuevaFecha.getMonth() + 1;
      let yyy = nuevaFecha.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      let datefull = dd + "/" + mm + "/" + yyy;

      return datefull;
    },

    nivelSeleccionado(params) {
      localStorage.nivelID = params;
    },
    statusColorDiscount(l) {
      if (l == "Pendiente") {
        return "#ff992a";
      } else if (l == "Aceptada") {
        return "success";
      } else if (l == "Denegada") {
        return "danger";
      } else if (!l) {
        return "#FFF";
      }
    },
    statusColor(l) {
      let newdate = new Date();
      let d = newdate.getDate();
      let m = newdate.getMonth() + 1;
      let y = newdate.getFullYear();
      let datefull = y + "-" + m + "-" + d;

      let fecha1 = l;
      let fecha2 = datefull;

      let f1 = new Date(fecha1);
      let f2 = new Date(fecha2);

      if (f1 < f2) {
        return "danger";
      } else {
        return "success";
      }
    },
    viewQuote(id) {
      localStorage.quoteID = id;
    },
    generatePDF(id) {
      localStorage.quoteID = id;
    },
  },
  components: {
    Datepicker,
  },
  computed: {
    checkData() {
      if (this.getUser == undefined) {
        return false;
      } else {
        return true;
      }
    },
    queriedItems() {
      return this.getUser.quotes;
    },
    ultimasCotizacione() {
      let cotizaciones;
      let filteredAccounts;
      let SolicitudesPendientes;

      let preDate;
      let postDate;
      if (this.getUser.quotes) {
        preDate = this.fromDate;
        postDate = this.toDate;
        cotizaciones = this.getUser.quotes;
        if (preDate >= postDate) {
          filteredAccounts = cotizaciones.filter(function (account) {
            return account.quote_date_created == preDate;
          });
        } else {
          filteredAccounts = cotizaciones.filter(function (account) {
            return (
              new Date(account.quote_date_created) >= preDate &&
              new Date(account.quote_date_created) <= postDate
            );
          });
        }

        SolicitudesPendientes = filteredAccounts.filter(function (i) {
          return i.request == "Denegada";
        });

        SolicitudesPendientes.sort(function (a, b) {
          return (
            new Date(b.quote_date_created) - new Date(a.quote_date_created)
          );
        });
      }

      return SolicitudesPendientes;
    },
  },
  mounted() {
     this.openLoading();
    setTimeout(() => {
      this.DataReady = true;
    }, 2000);
    this.levelSelected = localStorage.selectedLevel;
    this.isMounted = true;
  },
  created() {
    let date = new Date();
    var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    var siguienteMes = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var ultimoDia = new Date(siguienteMes - 1);

    this.fromDate = primerDia;
    this.toDate = ultimoDia;
  },
};
</script>

<style lang="scss">
[dir] .flatpickr-calendar .flatpickr-day.selected {
  background: #448aff !important;
  box-shadow: none;
  border-color: #448aff !important;
}
[dir] .flatpickr-input {
  background-color: #e1edff !important;
}
.popup-validacion {
  margin-bottom: 15px;
}
.selector {
  margin-bottom: 15px;
}
.margin-person {
  margin-bottom: 10px;
}
.font-bold {
  font-size: 21px;
}
.fecha1 {
  padding-right: 15px;
}
#data-list-thumb-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 0px;
      margin-right: 0px;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: left;
            }

            .proyect_Thumbnail {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 10px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .dropdown-button-container {
      display: flex;
      align-items: center;

      .btnx {
        border-radius: 5px 0px 0px 5px;
      }

      .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .vs-checkbox-small .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
      margin-top: 0vh !important;
    }
  }
}
</style>
