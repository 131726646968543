<template>
    <div>
        <vx-card title="📃📁 Cotizaciones con descuento solicitado" title-color="black">
      <p class="mb-1">En esta sección encontrara todas las cotizaciones con solicitud de descuento.</p>
    </vx-card>
    <br/>
    <listadoCotizacionesComponent/>
    </div>
</template>

<script>
import listadoCotizacionesComponent from '../components/DenegadasComponet'
    export default {
        data(){
            return{

            }
        },
        components:{
            listadoCotizacionesComponent
        },
        mounted(){
            this.$store.dispatch("autoLogin");
        }
    }
</script>

<style lang="scss" scoped>

</style>